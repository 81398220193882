import React from "react";
import { Col, Typography, Image, Divider, Row } from "antd";
import footerImg from "../../../assets/Logo.png";
import { footerIcon } from "../../../domain/footerIcons";
import footerImg2 from "../../../assets/footerImg.png";
import "../Footer/footer.css";

const Footer = () => {
  const { Text, Title, Link } = Typography;

  return (
    <footer>
      <Row className="footer-main-row">
        <Col span={24} style={{ display: "flex", padding: "5%" }}>
          <Col xs={0} lg={8} className="logo-section">
            <div className="logo-section-wrapper">
              <Image
                src={footerImg}
                style={{ marginBottom: "30px" }}
                width={100}
                className="footer-logo"
                alt="logo"
                preview={false}
              />
              <br />
              <Col style={{ display: "flex" }}>
                {footerIcon.map((item, index) => {
                  return (
                    <Link target="_blank" href={item.url} className="icon-col">
                      <Image
                        key={index}
                        src={item.image}
                        className="social-icons"
                        alt="social-icon"
                        preview={false}
                      />
                    </Link>
                  );
                })}
              </Col>
            </div>
          </Col>

          <Col xs={24} lg={8} style={{ display: "flex" }}>
            <Col className="quick-links-col" xs={12} lg={20}>
              <Title level={4} className="quick-link-head">
                Quick Links
              </Title>
              <Col className="quick-link-links-col">
                <ul style={{ listStyle: "none", paddingLeft: "0" }}>
                  <li>
                    <Link href="/home" className="quick-links">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link href="/eStore" className="quick-links">
                      Products
                    </Link>
                  </li>
                  <li>
                    <Link href="/qo-ommunity" className="quick-links">
                      Qommunity
                    </Link>
                  </li>
                  {/* <li>
                    <Link href="/contact-us" className="quick-links">
                      Contact Us
                    </Link>
                  </li> */}
                </ul>
              </Col>
              <Col className="social-links">
                {footerIcon.map((item, index) => {
                  return (
                    <Link target="_blank" href={item.url} className="icon-col">
                      <Image
                        key={index}
                        src={item.image}
                        className="social-icons"
                        alt="social-icon"
                        preview={false}
                      />
                    </Link>
                  );
                })}
              </Col>
            </Col>

            <Col className="quick-links-col" xs={12} lg={20}>
              <Title level={4} className="quick-link-head">
                Contact Us
              </Title>
              <Col className="quick-link-links-col">
                <Link className="quick-links">info@qoqotech.in </Link>
                <Link className="quick-links">
                  B38, Old DLF Colony, <br />
                  Sec 14, Gurgaon, Haryana, 122001
                </Link>
                <Link className="quick-links">www.qoqotech.in</Link>
              </Col>
            </Col>
          </Col>

          <Col span={8} className="footer-img-2-col">
            <Image
              width={200}
              src={footerImg2}
              alt="footer-img"
              preview={false}
            />
          </Col>
        </Col>
        <Row
          style={{
            marginTop: "40px",
            marginLeft: "5%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col
            style={{
              padding: "0 10px 0 0",
            }}
          >
            <Link className="quick-links" href="/privacy-policy">
              <li style={{ listStyle: "none" }}>Privacy Policy</li>
            </Link>
          </Col>
          <Divider className="link-divider" type="vertical" />
          <Col style={{ padding: "0 10px" }}>
            <Link className="quick-links" href="/terms-and-conditions">
              <li style={{ listStyle: "none" }}>Terms And Conditions</li>
            </Link>
          </Col>
          <Divider className="link-divider" type="vertical" />
          <Col style={{ padding: "0 10px" }}>
            <Link className="quick-links" href="/replacement-and-refund-policy">
              <li style={{ listStyle: "none" }}>Replacement / Refund Policy</li>
            </Link>
          </Col>
        </Row>
        <Divider orientation="center" className="divider" />
        <Text className="copyright-text">Copyright 2024, QOQO TECH</Text>
      </Row>
    </footer>
  );
};

export default Footer;
