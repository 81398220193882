import React from "react";
import "../AboutSections/styles/SecondSection.css";
import { Col, Row, Typography, Image } from "antd";
import sWatch from "../../../assets/vision2.svg";

const AboutSecTwoMobile = () => {
  const { Text } = Typography;
  return (
    <>
      <Row>
        <Col span={24} style={{ marginTop: "-50px" }}>
          <div className="about-second-top">
            <Image
              className="aboutbanner-img"
              src={sWatch}
              width="100%"
              alt="watch-img"
              height="509px"
              preview={false}
            />
            <div className="right">
              <div className="img-content-mobile">
                  <Text className="title">
                    Vision
                  </Text>
                  <Text className="sub-title">
                    To be the global leaders in the AI-IoT segment
                  </Text>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default AboutSecTwoMobile;
