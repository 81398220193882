import React, { useState, useEffect } from "react";
import { Layout, Button, Drawer, Image, Menu, Badge } from "antd";
import LeftMenu from "./LeftMenu.js";
import RightMenu from "./RightMenu.js";
import { MenuOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../assets/Logo.png";
import "./styles/menu.css";
import { useSelector } from "react-redux";
import cartIcon from "../../../assets/Bag.svg";

const MainMenu = () => {
  const [visible, setVisible] = useState(false);
  const cartItems = useSelector((state) => state.cart.cartItems);

  const closeModal = () => {
    const modalContainer = document.getElementById("modalContainer");
    modalContainer.style.display = "none";
  };
  const showDrawer = () => {
    setVisible(!visible);
    closeModal();
  };

  let { pathname: location } = useLocation();
  useEffect(() => {
    setVisible(false);
  }, [location]);
  // Upto here

  return (
    <nav className="navbar">
      <Layout>
        <Layout.Header className="nav-header">
          <Link to="/" className="logo">
            <Image
              src={logo}
              className="brand-font"
              alt="logo"
              height={100}
              preview={false}
            />
          </Link>
          <div className="navbar-menu">
            {window.innerWidth > 480 ? (
              <>
                <div className="leftMenu" style={{ width: "50%" }}>
                  <LeftMenu mode={"horizontal"} />
                </div>
                <div className="rightMenu">
                  <RightMenu mode={"horizontal"} />
                </div>
              </>
            ) : (
              <>
                <div className="btns-container">
                  <Button className="cartButton" type="text" href="/cart">
                    <Badge count={cartItems?.length}>
                      <Image src={cartIcon} alt="cart-icon" preview={false} />
                    </Badge>
                  </Button>
                  <Button
                    className="menuButton"
                    type="text"
                    onClick={showDrawer}
                  >
                    <MenuOutlined />
                  </Button>
                </div>
                <Drawer
                  title={
                    <Image
                      src={logo}
                      className="brand-font"
                      width={100}
                      alt="logo"
                      preview={false}
                    />
                  }
                  placement="right"
                  closable={true}
                  onClose={showDrawer}
                  visible={visible}
                  style={{ zIndex: 99999 }}
                >
                  <LeftMenu mode={"inline"} />
                  <RightMenu setDrawerVisible={setVisible} mode={"inline"} />
                </Drawer>
              </>
            )}
          </div>
          <div className="model-init">
            <div
              className="modal-container"
              id="modalContainer"
              style={{ display: "none" }}
              onClick={closeModal}
            >
              <div className="modal">
                <span className="close-icon" onClick={closeModal}>
                  &times;
                </span>
                <div id="otpless-login-page" custom="true"></div>
              </div>
            </div>
          </div>
        </Layout.Header>
      </Layout>
    </nav>
  );
};

export default MainMenu;
