import { Slider } from "antd";

const PriceFilter = ({ onPriceSelected, selectedPrice }) => {
  // Define the minimum and maximum price values
  const minPrice = 0; // 1k
  const maxPrice = 100; // 100k

  // Initialize the default values for the Slider
  const defaultValue = [minPrice, maxPrice];
  const onPriceChange = (value) => {
    onPriceSelected(value);
  };

  return (
    <div>
      <h4>Filter by price</h4>
      <Slider
        range
        defaultValue={defaultValue}
        min={minPrice}
        max={maxPrice}
        value={selectedPrice}
        onChange={onPriceChange}
      />
      <p>{`Price ${minPrice}K - ${maxPrice}K`}</p>
    </div>
  );
};
export default PriceFilter;
