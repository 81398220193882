import React, { useState, useEffect } from "react";
import { Button, Col, Row, Card, Layout, Typography, message } from "antd";
import { useNavigate } from "react-router-dom";
import { getUserAddress, removeAddress } from "../../api/user";
import { generateAddressString } from "../../utils/user";
import { PlusOutlined } from "@ant-design/icons";
import { Address } from "../../Components/Address/Address";

const AddressPage = () => {
  const [addressList, setAddressList] = useState([]);
  const [isNewAddressAdd, setIsNewAddressAdd] = useState(false);
  const [isEditAddress, setIsEditAddress] = useState(false);
  const [addressSelected, setAddressSelected] = useState();

  const addAddressStyle = {
    background: "rgba(245, 245, 245)",
    height: 260,
    margin: "2px 0",
    border: "1px dashed #848397",
    borderRadius: 6,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  };

  const redTextButton = {
    color: "#ED302C",

    border: "none",
    background: "none",
    outline: "none",
    cursor: "pointer",
  };
  const addressredTextButton = {
    color: "red",
    textDecoration: "underline",
  };
  const navigate = useNavigate();
  const checkUserLoggedIn = () => {
    const otpless_user_token = JSON.parse(
      localStorage.getItem("otpless_user_token")
    );

    if (!otpless_user_token) {
      navigate("/eStore");
    }
  };
  useEffect(() => {
    checkUserLoggedIn();
  }, []);

  const getAndStoreUserAddress = async () => {
    const addressList = await getUserAddress();
    setAddressList(addressList);
    console.log(addressList);
  };
  useEffect(() => {
    getAndStoreUserAddress();
  }, [isEditAddress, isNewAddressAdd]);

  const handleAddressEdit = async (address) => {
    setIsEditAddress(true);
    setAddressSelected(address);
  };

  const handleAddressRemove = async (address) => {
    removeAddress(address).then((res) => {
      message.success("Address removed successfully");
      getAndStoreUserAddress();
    });
  };

  return (
    <Layout className="user-layout">
      {isNewAddressAdd || isEditAddress ? (
        <Address
          setIsNewAddressAdd={setIsNewAddressAdd}
          setIsEditAddress={setIsEditAddress}
          isEditAddress={isEditAddress}
          addressSelected={addressSelected}
        />
      ) : (
        <Row gutter={16}>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={8}
            xl={8}
            style={{ padding: "0 10px" }}
          >
            <Card
              style={addAddressStyle}
              hoverable
              onClick={() => {
                setIsNewAddressAdd(true);
              }}
            >
              <div style={{ marginLeft: 20, paddingBottom: 5 }}>
                <PlusOutlined style={{ fontSize: "32px" }} />
              </div>
              <Typography.Text>Add address</Typography.Text>
            </Card>
          </Col>
          {addressList.map((address) => (
            <Col
              span={8}
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              key={address.id}
            >
              <Card
                bordered={false}
                style={{ marginBottom: "70px", height: "260px" }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                    marginBottom: "5px",
                  }}
                >
                  {address.name}
                </Typography>{" "}
                <br />
                <Typography
                  style={{
                    fontSize: "12px",
                    fontWeight: "600",
                    wordBreak: "break-all",
                  }}
                >
                  {generateAddressString(address, false)}
                </Typography>
                <br />
                Phone : {address.contact}
                <br />
                Email : {address.email}
                <br />
                <br />
                <Row
                  gutter={[16]}
                  align="middle"
                  style={{ marginBottom: "40px" }}
                >
                  <Col>
                    <Button
                      onClick={() => handleAddressEdit(address)}
                      style={redTextButton}
                    >
                      Edit
                    </Button>
                  </Col>
                  |
                  <Col>
                    <Button
                      onClick={() => handleAddressRemove(address)}
                      style={redTextButton}
                    >
                      Remove
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </Layout>
  );
};

export default AddressPage;
