import React from "react";
import { createBrowserRouter } from "react-router-dom";
import LayoutPage from "../pages/Layout.jsx";
import UserLayoutPage from "../pages/UserLayout.jsx";
import Home from "../presentation/screen/Home.jsx";
import Products from "../presentation/screen/Products.jsx";
import Qommunity from "../presentation/screen/Qommunity.jsx";
import AboutUs from "../presentation/screen/AboutUs.jsx";
import ProductSummary from "../presentation/screen/ProductSummary.jsx";
import ProductsStore from "../Screens/ProductsStore.jsx";
import ProductDetails from "../Screens/ProductDetail.jsx";
import Cart from "../Screens/Cart.jsx";
import ShippingSectionPage from "../Screens/ShippingSectionPage.jsx";
import ChosePaymentSection from "../Screens/ChosePaymentSection.jsx";
import UserAddressSection from "../Screens/UserAddressSection.jsx";
import ProfilePage from "../Screens/User/ProfilePage.jsx";
import OrderPage from "../Screens/User/OrderPage.js";
import AddressPage from "../Screens/User/AddressPage.js";
import TermsAndConditions from "../Screens/TermsAndConditions.jsx";
import ReplacementAndRefundPolicy from "../Screens/ReplacementAndRefundPolicy.jsx";
import PrivacyPolicy from "../Screens/PrivacyPolicy.jsx";
import PaymentResponsePage from "../Components/Payment/PaymentResponsePage.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LayoutPage Component={Home} />,
  },
  {
    path: "/home",
    element: <LayoutPage Component={Home} />,
  },
  {
    path: "/product",
    element: <LayoutPage Component={Products} />,
  },
  {
    path: "/product/:product_id",
    element: <LayoutPage Component={ProductDetails} />,
  },
  {
    path: "/eStore",
    element: <LayoutPage Component={ProductsStore} />,
  },
  {
    path: "/product-summary",
    element: <LayoutPage Component={ProductSummary} />,
  },
  {
    path: "/qo-ommunity",
    element: <LayoutPage Component={Qommunity} />,
  },
  {
    path: "/about-us",
    element: <LayoutPage Component={AboutUs} />,
  },
  {
    path: "/cart",
    element: <LayoutPage Component={Cart} />,
  },
  {
    path: "/checkout/shipping",
    element: <LayoutPage Component={ShippingSectionPage} />,
  },
  {
    path: "/checkout/payment",
    element: <LayoutPage Component={ChosePaymentSection} />,
  },
  {
    path: "/checkout/address",
    element: <LayoutPage Component={UserAddressSection} />,
  },
  {
    path: "/user",
    element: <UserLayoutPage Component={ProfilePage} />,
  },
  {
    path: "/user/profile",
    element: <UserLayoutPage Component={ProfilePage} />,
  },
  {
    path: "/user/orders",
    element: <UserLayoutPage Component={OrderPage} />,
  },
  {
    path: "/user/address",
    element: <UserLayoutPage Component={AddressPage} />,
  },
  {
    path: "/terms-and-conditions",
    element: <LayoutPage Component={TermsAndConditions} />,
  },
  {
    path: "/replacement-and-refund-policy",
    element: <LayoutPage Component={ReplacementAndRefundPolicy} />,
  },
  {
    path: "/privacy-policy",
    element: <LayoutPage Component={PrivacyPolicy} />,
  },
  {
    path: "/payment-response",
    element: <LayoutPage Component={PaymentResponsePage} />
  }
]);

export default router;
