import React from "react";
import { Col, Image } from "antd";
import community from "../../assets/community.png";
import Footer from "../components/Footer/Footer.jsx";
import { Helmet } from "react-helmet";

const Qommunity = () => {
  return (
    <>
      <Helmet>
        <title>Qommunity | QOQO TECH</title>
        <meta name="description" content="This is a description of my page" />
        <meta name="keywords" content="react, meta tags, seo" />
        <meta name="author" content="QoQo Tech" />
        <meta property="og:title" content="Qommunity | QOQO TECH" />
        <meta
          property="og:description"
          content="This is a description of my page"
        />
      </Helmet>
      <Col span={24}>
        <Image src={community} alt="commuity" preview={false} />
        <Footer />
      </Col>
    </>
  );
};

export default Qommunity;
