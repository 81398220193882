import React, { useState } from "react";
import { Select, Typography, Pagination, Avatar } from "antd";
import { Rate } from "antd";
const { Text } = Typography;

const ProductDetailReview = ({ productData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 3;

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = currentPage * pageSize;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div style={{ paddingTop: "30px", fontWeight: 600 }}>
        <div
          style={{ borderBottom: "1px solid #ccc", marginTop: "40px" }}
        ></div>
        {productData?.reviews?.slice(startIndex, endIndex).map((review) => (
          <div
            key={review.id}
            style={{
              borderBottom: "1px solid #ccc",
              marginTop: "30px",
              paddingBottom: "30px",
              display: "flex",
            }}
          >
            <div style={{ minWidth: "48px", marginRight: "20px" }}>
              <Avatar shape="circle" size={48}>
                {" "}
                {review.username?.slice(0, 1)}
              </Avatar>
            </div>
            <div>
              <Typography>
                <Text style={{ fontWeight: "bold", marginRight: "4px" }}>
                  {review.username}
                </Text>
                <span style={{ color: "black" }}>•</span>
                <Text
                  style={{
                    marginRight: "4px",
                    marginLeft: "4px",
                    color: "gray",
                    fontWeight: "normal",
                  }}
                >
                  {review.dateReviewed}
                </Text>
              </Typography>
              <Typography style={{ marginTop: "10px" }}>
                <Rate
                  disabled
                  defaultValue={review.rating}
                  style={{ color: "#FF902D", marginLeft: "-20px" }}
                  character={
                    <span style={{ fontSize: "20px", letterSpacing: "-8px" }}>
                      &#9733;
                    </span>
                  }
                />
              </Typography>
              <Typography style={{ color: "#777586", fontWeight: "normal" }}>
                {review.review}
              </Typography>
            </div>
          </div>
        ))}
        <div style={{ paddingTop: "50px" }}>
          {/* Button positioned at top-right corner */}
          <div style={{ position: "relative" }}>
            {/* <Button
              // htmlType="submit"
              onClick={() => {
                setReviewModal(true);
                form.setFieldsValue({
                  rating: 0,
                  review: "",
                });
              }}
              disabled={authState.authToken === null}
              style={
                authState.authToken === null
                  ? {
                      color: "gray",
                      height: "48px",
                      width: "12%",
                    }
                  : {
                      backgroundColor: "#04b2a9",
                      color: "white",
                      height: "48px",
                      width: "12%",
                    }
              }
            >
              Write a Review
            </Button> */}
            <Pagination
              current={currentPage}
              total={productData?.reviews?.length}
              pageSize={pageSize}
              onChange={handlePageChange}
              style={{
                position: "absolute",
                top: "-20px",
                right: "-30px",
                zIndex: 1,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetailReview;
