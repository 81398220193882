import React, { useEffect, useState } from "react";
import { Col, Typography, Image, Card, Button, Rate, message } from "antd";
import { HeartOutlined } from "@ant-design/icons";
//@ts-ignore
import vector5 from "../../assets/swatch.png";
import "./style/product.css";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateCartItem } from "../../Redux/actionTypes";
import { addProductToCart, getUserCartList, userLogin } from "../../api/user";
import {
  setAuthToken,
  setUserDetails,
} from "../../Redux/actionTypes/authAction";
import { initOTPless } from "../../utils/initOtpless";

const { Text } = Typography;

const Product = ({ product }) => {
  const [isWishlistClicked, setIsWishlistClicked] = useState(false);
  const [isItemAddeToCart, setIsItemAddeToCart] = useState(false);
  const [userData, setUserData] = useState(
    localStorage.getItem("otpless_user_token")
  );
  const dispatch = useDispatch();
  const closeModal = (e) => {
    const modalContainer = document.getElementById("modalContainer");
    modalContainer.style.display = "none";
  };

  const callback = async (otplessUser) => {
    const tokenObj = {
      token: otplessUser.token,
      email: otplessUser.email?.email,
      waNumber: otplessUser.identities[0].identityValue,
      waName: otplessUser.email?.name !== null ? otplessUser.email?.name : "",
    };
    const userData = await userLogin(tokenObj);
    const { auth_token, ...userInfo } = userData.data.data;
    // Save token and user info in localStorage
    localStorage.setItem("auth_token", auth_token);
    localStorage.setItem("user_details", JSON.stringify(userInfo));
    localStorage.setItem("otpless_user_token", JSON.stringify(tokenObj));
    setUserData(otplessUser);
    dispatch(setUserDetails(userInfo));
    getUserCartList().then((response) => {
      dispatch(updateCartItem(response));
    });
    closeModal();
  };

  const openModal = () => {
    if (userData) {
      return;
    }
    initOTPless(callback);
    const modalContainer = document.getElementById("modalContainer");
    if (modalContainer) {
      modalContainer.style.display = "flex";
    }
    // modalContainer !== null ? (modalContainer.style.display = "flex") : "";
  };

  const handleAddToCart = (itemDetails) => {
    const otpless_user_token = JSON.parse(
      localStorage.getItem("otpless_user_token")
    );

    if (!otpless_user_token) {
      openModal();
    } else {
      const productDetailsAndCount = {
        product_id: itemDetails.id,
        count: 1,
      };
      addProductToCart(productDetailsAndCount).then((response) => {
        message.success("Product added succesfully");
        getUserCartList().then((response) => {
          console.log(response);
          dispatch(updateCartItem(response));
        });
      });
      // dispatch(addToCart(productDetailsAndCount)); // Dispatch action to add item to cart
      setIsItemAddeToCart(true);
    }
  };

  const wishlistIconColor = isWishlistClicked ? "red" : "gray"; // Define the colors

  const handleWishlistClick = () => {
    setIsWishlistClicked(!isWishlistClicked); // Toggle the state
  };

  const getAverageRating = (reviews) => {
    if (reviews?.length === 0) {
      return 0; // Return 0 if there are no reviews
    }

    const totalRating = reviews?.reduce((accumulator, review) => {
      return accumulator + review.rating;
    }, 0);

    return Math.round(totalRating / reviews?.length);
  };

  return (
    <Card
      hoverable
      className="third-card"
      style={{ minWidth: 225, margin: 10, position: "relative" }}
    >
      <Col span={24} className="third-card-img-container">
        <Link to={`/product/${product.id}`}>
          <Image
            className="third-card-img"
            alt="example"
            src={product?.productImages?.[0]}
            preview={false}
          />
        </Link>
        {/* <HeartOutlined
          className="wishlist-icon"
          style={{
            fontSize: "24px",
            position: "absolute",
            top: "10px",
            right: "10px",
            color: wishlistIconColor,
          }}
          onClick={handleWishlistClick}
        /> */}
      </Col>
      <Col span={24} className="card-details-container">
        <Text className="third-card-title">{product.name}</Text>
        <Rate
          allowHalf
          disabled
          // defaultValue={getAverageRating(product.reviews) || 0}
        />
        <div>
          <div>
            <Text className="third-card-price" delete>
              ₹{product.productPrice}
            </Text>
            <Text className="third-card-price"> - {product.discount}%</Text>
          </div>
          <div>
            <Text className="third-card-price" style={{ color: "green" }}>
              ₹{product.sellingPrice}
            </Text>
          </div>
        </div>
        <Button
          className="third-view-all-btn"
          onClick={() => {
            handleAddToCart(product);
          }}
        >
          {"Add to cart"}
        </Button>
      </Col>
    </Card>
  );
};

export default Product;
