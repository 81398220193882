import { Row, Col, Typography, List } from "antd";
import Title from "antd/es/skeleton/Title";
import React from "react";

const ReplacementAndRefundPolicy = () => {
  const { Title, Paragraph } = Typography;
  return (
    <>
      <Row type="flex" justify="center" align="top">
        <Col span={18} style={{ textAlign: "center" }}>
          <Title level={1}>Replacement and Refund Policy</Title>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            <b>QOQO Tech will provide a replacement for a product </b> if
            informed within 48 hours of receiving the products and under the
            following conditions:
          </Paragraph>
          <ul style={{ textAlign: "left" }}>
            <li>Wrong Product has been received</li>
            <li>Damaged product has been received</li>
            <li>Documentary evidence in the form of a photo or video</li>
          </ul>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            Replacement can be made if the customer reports the same to our
            Customer Service team by writing an email to info@qoqotech.in
          </Paragraph>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            Once the issue has been confirmed by the QOQO Tech service team,
            QOQO Tech will initiate a Return pickup for the Replacement order
            and the customer will be responsible to ensure the faulty product is
            successfully picked up by the Reverse Courier Boy. Once the faulty
            product gets successfully picked up by our courier partner, QOQO
            Tech will then dispatch a fresh replacement unit.
          </Paragraph>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            A product purchased at QOQO Tech will not be eligible for a
            replacement if the customer is not happy with the look/sound quality
            of a defect-free product, physical damage / liquid damage or
            tampering caused by the user.
          </Paragraph>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            The policy is only valid if the concern is raised within 48 hrs. of
            delivery. Further a customer is eligible for replacement only once,
            if the issue persists even with the replacement unit, they must
            visit the service center, unless the replacement product is
            absolutely dead on arrival.
          </Paragraph>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            Products purchased from the QOQO Tech website are not{" "}
            <b>eligible for a refund.</b>
          </Paragraph>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            <b>Cancellation Policy </b>
          </Paragraph>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            Cancellations are not entertained for the orders placed/purchased
            through any offer or discount during special occasions.
          </Paragraph>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            Users can cancel their placed order until it has not been initiated
            for the shipment process.
          </Paragraph>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            Please note, the company reserves the right to cancel any prepaid or
            COD orders if we are unable to honor the same for any reason. In
            cases of prepaid cancellations, the customer will receive a refund
            within 48-72 hours.
          </Paragraph>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            For any other queries, you can reach out to our Customer Service
            team by sending an email to: info@qoqotech.in
          </Paragraph>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            <b>Shipping Policy </b>
          </Paragraph>
          <ul style={{ textAlign: "left" }}>
            <li>
              Each order will be shipped only to a single destination address
              specified at the time of the payment for that order. If you wish
              to ship products to different addresses, you shall do so by
              placing multiple orders.
            </li>
            <li>
              We make our best efforts to ensure that each item in your order is
              shipped out within 24 hours of the order getting placed. However,
              in some cases, it takes longer, up to 5 or more working days, to
              ship the order as the product may have to be procured by the
              seller where there is a heavy demand.
            </li>
            <li>
              Orders are shipped out on all weekdays (Monday to Saturday),
              excluding public holidays.
            </li>
            <li>
              Please examine the product at the time on delivery, to verify if
              the packaging is tampered or damaged. If the product is not in the
              ideal condition, please refuse the collection of the package, and
              e-mail our customer service team at info@qoqotech.in mentioning
              your order reference number and details. We shall make our best
              efforts to ensure that a replacement delivery is made to you at
              the earliest.
            </li>
            <li>
              Please note, all the orders placed on the QOQO Tech website are
              shipped with an invoice. In case, inadvertently, if the same is
              not received, you may download the same by logging into the
              account section on website (www.qoqotech.in) with the same email
              address used while making the purchase.
            </li>
          </ul>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            <b>Warranty Policy </b>
          </Paragraph>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            <b>THIS WARRANTY DOES NOT COVER </b>
          </Paragraph>
          <ul style={{ textAlign: "left" }}>
            <li>
              Products with defects or damage resulting from the use of the
              Product in conjunction or connection with accessories, products
              not approved by QOQO Tech.{" "}
            </li>
            <li>
              Damage or inoperability caused by repair work performed by the end
              user or any unauthorized repair centre.{" "}
            </li>
            <li>
              Any software programs, whether provided with the product or
              installed subsequently.{" "}
            </li>
            <li>Any external or physical damage on the product. </li>
          </ul>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            <b>DAMAGE: </b>This warranty does not cover any physical and/or
            electrical damage resulting from, but not limited to:
          </Paragraph>
          <ul style={{ textAlign: "left" }}>
            <li>Accident/neglect </li>
            <li>Misuse </li>
            <li>Abuse </li>
            <li>Malicious destruction </li>
            <li>Ordinary wear and tear </li>
            <li>Acts of nature </li>
            <li>
              The temporary or permanent affixing of any items supplied or not
              supplied by the manufacturer with any adhesive, fastener or the
              like.{" "}
            </li>
            <li>
              Overcharging, use of batteries, power supplies or any other
              sources of power not recommended by the manufacturer.{" "}
            </li>
            <li>
              Any physical defect or damage resulting from improper testing,
              operation, maintenance, installation, service, or adjustment
              caused not furnished or approved by QOQO Tech.{" "}
            </li>
          </ul>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            The QOQO Tech customer service team shall reserve to right to final
            decision for all potential warranty claims for service and
            replacement or same colour preference (depending upon stock
            availability).
          </Paragraph>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            <b>PLEASE NOTE: </b>
          </Paragraph>
          <ul style={{ textAlign: "left" }}>
            <li>
              Without a valid Proof of Purchase, QOQO Tech reserves the rights
              to refuse warranty services.{" "}
            </li>
            <li>
              QOQO Tech Audio reserves the right to refuse ‘free of charge’
              warranty claim if the information shared is incomplete/ illegible
              for the claim / fault in the Customer details.{" "}
            </li>
            <li>
              This warranty is non-transferrable. This warranty shall be the
              purchaser’s sole remedy and neither QOQO Tech nor its service
              centres shall be liable for incidental or consequential damage or
              breach of any expressed or implied warranty of this product.{" "}
            </li>
            <li>
              Repair or replacement under the terms of warranty does not provide
              right to extension or renewal of the warranty period.{" "}
            </li>
            <li>
              Warranty repairs must be carried out by QOQO Tech authorized
              service centres only. Warranty cover will be void, if and when
              unauthorized service has been attempted by the user or any
              unauthorized service centre.{" "}
            </li>
            <li>
              QOQO Tech may use rebuilt, reconditioned or refurbished parts
              and/or components when repairing or replacing a defective product.{" "}
            </li>
            <li>
              In case of delivery destination being unavailable, shipping
              charges must be handled by the buyer.{" "}
            </li>
            <li>
              QOQO Tech may use your contact information to inform you about our
              new and better products and deals in the future.{" "}
            </li>
            <li>
              Within/after the warranty claim requests raised by the customers;
              Brand has the rights to contact the customers regarding any
              communication about their warranty claims.{" "}
            </li>
          </ul>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            <b>OUT OF WARRANTY PRODUCTS: </b>
          </Paragraph>
          <ul style={{ textAlign: "left" }}>
            <li>
              In case your product is out of warranty (Not physically or water
              damaged) The repair will be done at a cost. Please contact to our
              customer support team via email at: info@qoqotech.in to know the
              repair charges.{" "}
            </li>
          </ul>
        </Col>
      </Row>
    </>
  );
};
export default ReplacementAndRefundPolicy;
