import React, { useEffect, useState } from "react";
import { Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import PaymentSection from "../Components/CheckOut/PaymentSection";
import OrderSummary from "../Components/CheckOut/OrderSummary";
import { createOrder, initiatePayment } from "../api/order";
import { useNavigate } from "react-router-dom";
import { deleteAllProductFromCart, getUserCartList } from "../api/user";
import OrderPlacedModal from "../modals/OrderPlacedModal";
import { updateCartItem } from "../Redux/actionTypes";

const ChosePaymentSection = () => {
  const [totalAmount, setTotalAmount] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(50);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const cartItems = useSelector((state) => state.cart.cartItems);
  const authState = useSelector((state) => (state ? state.auth : []));
  const CC_AVENUE_MERCHANT_ID = process.env.REACT_APP_CC_AVENUE_MERCHANT_ID;
  const CC_AVENUE_ACCESS_KEY = process.env.REACT_APP_CC_AVENUE_ACCESS_KEY;

  const dispatch = useDispatch();
  const onModalClose = () => {
    setIsModalOpen(false);
  };
  const onOrderPlace = (address, paymentMethod) => {
    if (paymentMethod === "CCAvenue") {
      console.log("cartItems", cartItems);
      const payload = {
        order_id: "",
        currency: "INR",
        amount: totalAmount + shippingCharge,
        redirect_url: `https://api.qoqotech.in/v1/api/order/payment-response?user=${authState?.authToken}`,
        billing_name: address.firstName + " " + address.lastName,
        billing_address: address.address,
        billing_city: address?.city,
        billing_state: address?.state,
        billing_zip: address?.pincode,
        billing_country: address?.country,
        billing_tel: address?.mobile || "",
        billing_email: authState?.userDetails?.email || "",
        merchant_param1: authState?.userDetails?.id,
        merchant_param2: address.id,
        merchant_param3: authState?.userDetails?.full_name || "Customer ",
      };
      initiatePayment(payload).then(async (response) => {
        const encryptedOrderData = response.data;
        window.location.href = `https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction&merchant_id=${CC_AVENUE_MERCHANT_ID}&access_code=${CC_AVENUE_ACCESS_KEY}&encRequest=${encryptedOrderData}`;
      });
    } else {
      const deliveryDate = new Date();
      deliveryDate.setDate(deliveryDate.getDate() + 3);
      const payload = {
        address_id: address.id,
        order_items: cartItems,
        amount: totalAmount + shippingCharge,
        payment_mode: paymentMethod,
        status: "Placed",
        customerName: authState?.userDetails?.full_name || "Customer ",
        estimated_delivery_date: deliveryDate,
      };
      createOrder(payload).then(async (response) => {
        console.log(response);
        deleteAllProductFromCart().then((response) => {
          getUserCartList().then((response) => {
            dispatch(updateCartItem(response));
            setIsModalOpen(true);
          });
        });
      });
      console.log(payload);
    }
  };
  const getTotalAmount = (cartItems) => {
    let totalAmount = 0;
    if (cartItems.length) {
      cartItems.forEach((item) => {
        totalAmount += item.sellingPrice * item.count;
      });
    }
    setTotalAmount(totalAmount);
    if (totalAmount >= 5000) {
      setShippingCharge(0);
    } else {
      setShippingCharge(50);
    }
  };
  const navigate = useNavigate();
  const checkUserLoggedIn = () => {
    const otpless_user_token = JSON.parse(
      localStorage.getItem("otpless_user_token")
    );

    if (!otpless_user_token) {
      navigate("/eStore");
    }
  };
  useEffect(() => {
    checkUserLoggedIn();
  }, []);
  useEffect(() => {
    getTotalAmount(cartItems);
  }, [cartItems]);
  return (
    <>
      <Row gutter={16} style={{ margin: 0 }} type="flex" justify="center" align="middle">
        <PaymentSection onOrderPlace={onOrderPlace} />
        <OrderSummary />
      </Row>
      <OrderPlacedModal isModalOpen={isModalOpen} onModalClose={onModalClose} />
    </>
  );
};

export default ChosePaymentSection;
