import React, { useCallback, useEffect, useState } from "react";
import { Menu, Avatar, Image, Badge } from "antd";
import userIcon from "../../../assets/Profile.svg";
import cartIcon from "../../../assets/Bag.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./menu.css";
import { getUserCartList, userLogin } from "../../../api/user";
import {
  setAuthToken,
  setUserDetails,
} from "../../../Redux/actionTypes/authAction";
import { updateCartItem } from "../../../Redux/actionTypes";
import { initOTPless } from "../../../utils/initOtpless";

const RightMenu = ({ mode, setDrawerVisible }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const authState = useSelector((state) => (state ? state.auth : []));
  const [userData, setUserData] = useState(
    window.localStorage.getItem("otpless_user_token")
  );
  const navigate = useNavigate();

  const callback = useCallback(
    async (otplessUser) => {
      const tokenObj = {
        token: otplessUser.token,
        email: otplessUser.email?.email,
        waNumber: otplessUser.identities[0].identityValue,
        waName: otplessUser.email?.name !== null ? otplessUser.email?.name : "",
      };
      const userData = await userLogin(tokenObj);
      const { auth_token, ...userInfo } = userData.data.data;
      // Save token and user info in localStorage
      localStorage.setItem("auth_token", auth_token);
      localStorage.setItem("user_details", JSON.stringify(userInfo));
      localStorage.setItem("otpless_user_token", JSON.stringify(tokenObj));
      setUserData(otplessUser);
      dispatch(setUserDetails(userInfo));
      closeModal();
      navigate(0);
    },
    [dispatch, navigate]
  );

  const openModal = useCallback(() => {
    if (userData) {
      return;
    }
    initOTPless(callback);
    const modalContainer = document.getElementById("modalContainer");
    if (modalContainer) {
      modalContainer.style.display = "flex";
    }
  }, [callback, userData]);

  const closeModal = () => {
    const modalContainer = document.getElementById("modalContainer");
    // if (e.target === modalContainer) {
    // 	modalContainer ? (modalContainer.style.display = 'none') : ''
    // }
    if (modalContainer) {
      modalContainer.style.display = "none";
    }
    const script = document.getElementById("otplessIdScript");
    if (script) {
      script.remove();
    }
    // script ? script.remove() : "";
  };

  useEffect(() => {
    setUserData(localStorage.getItem("otpless_user_token"));
  }, [location.pathname]);
  useEffect(() => {
    if (userData === null && location.pathname === "/user/profile") {
      openModal();
    } else {
      closeModal();
    }
  }, [userData, location.pathname, openModal]);

  const initializeApp = () => {
    const auth_token = localStorage.getItem("auth_token");
    const user_details = JSON.parse(localStorage.getItem("user_details"));

    if (auth_token && user_details) {
      // Dispatch actions to set token and user info in Redux store
      dispatch(setAuthToken(auth_token));
      dispatch(setUserDetails(user_details));
      getUserCartList().then((response) => {
        console.log(response);
        dispatch(updateCartItem(response));
      });
    }
  };
  const onUserAlreadyLoggedIn = () => {
    const otplessUserToken = JSON.parse(
      localStorage.getItem("otpless_user_token")
    );

    if (otplessUserToken) {
      console.log("yes user alrady logged in");
      navigate("/user/profile");
    }
  };

  const cartItems = useSelector((state) => state.cart.cartItems);

  return (
    <Menu mode={mode}>
      {/* <div className="model-init">
        <div
          className="modal-container"
          id="modalContainer"
          onClick={closeModal}
        >
          <div className="modal">
            <span className="close-icon" onClick={closeModal}>
              &times;
            </span>
            <div id="otpless-login-page" custom="true"></div>
          </div>
        </div>
      </div> */}

      <Menu.Item className="menu-item" key="user">
        <Link
          onClick={() => {
            if (authState?.userDetails === null) {
              if (setDrawerVisible) {
                setDrawerVisible(false);
              }
              openModal();
            } else {
              navigate("/user/profile");
            }
          }}
        >
          <Image src={userIcon} alt="user-icon" preview={false} />
        </Link>
      </Menu.Item>
      <Menu.Item className="menu-item" key="cart">
        <Link to="/cart">
          <Badge count={cartItems?.length}>
            <Image src={cartIcon} alt="cart-icon" preview={false} />
          </Badge>
        </Link>
      </Menu.Item>
    </Menu>
  );
};

export default RightMenu;
