import React, { useEffect, useState } from "react";
import AboutSecOne from "../components/AboutSections/FirstSection";
import Footer from "../components/Footer/Footer";
import AboutSecTwo from "../components/AboutSections/SecondSections";
import AboutSecThird from "../components/AboutSections/ThirdSection";
import AboutSecForth from "../components/AboutSections/ForthSection";
import AboutSecForthMobile from "../components/AboutSections/ForthSectionMobile";
import AboutSecOneMobile from "../components/AboutSections/FirstSectionMobile";
import AboutSecTwoMobile from "../components/AboutSections/SecondSectionMobile";
import AboutSecThirdMobile from "../components/AboutSections/ThirdSectionMobile";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    // Event listener for window resize
    window.addEventListener("resize", updateWindowWidth);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", updateWindowWidth);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>About Us | QOQO TECH</title>
        <meta name="description" content="This is a description of my page" />
        <meta name="keywords" content="react, meta tags, seo" />
        <meta name="author" content="QoQo Tech" />
        <meta property="og:title" content="About Us | QOQO TECH" />
        <meta
          property="og:description"
          content="This is a description of my page"
        />
      </Helmet>
      {windowWidth > 500 ? (
        <>
          <AboutSecOne />
          <AboutSecTwo />
          <AboutSecThird />
          <AboutSecForth />
        </>
      ) : (
        <>
          <AboutSecOneMobile />
          <AboutSecTwoMobile />
          <AboutSecThirdMobile />
          <AboutSecForthMobile />
        </>
      )}
      <Footer />
    </>
  );
};

export default AboutUs;
