import { Row, Col, Typography } from "antd";
import Title from "antd/es/skeleton/Title";
import React from "react";

const PrivacyPolicy = () => {
  const { Title, Paragraph } = Typography;
  return (
    <>
      <Row type="flex" justify="center" align="top">
        <Col span={18} style={{ textAlign: "center" }}>
          <Title level={1}>Privacy policy</Title>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            This Privacy Policy describes how your personal information is
            collected, used, and shared when you visit or make a purchase from
            www.qoqotech.in
          </Paragraph>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            SYMPLTECH SINGULARITY SOLUTIONS PVT LTD (hereafter “QOQO Tech”) is
            committed to protecting the privacy of its visitors and users
            (hereafter the "Visitors," "you" or “your”) to our Internet World
            Wide Web site www.qoqotech.in and/or mobile application/s (hereafter
            referred to as the “Website”), and your usage of our services and
            products, that links directly to this privacy policy about the
            protection of personal information online. We aim for the Website to
            be a safe and enjoyable environment for you.
          </Paragraph>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            This privacy policy (hereafter the "Policy") primarily describes:
          </Paragraph>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            the information that QOQO Tech collects through the Website or
            otherwise, which is hosted and operated from India;
          </Paragraph>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            the use and role of cookies and similar technologies on the Website
            and the security measures QOQO Tech has in place to protect the
            information that QOQO Tech gathers through the Website or otherwise.
          </Paragraph>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            This Policy also governs QOQO Tech's collection of information by
            any other means, including through your usage of our services and
            products or the collection of during your employment or business
            association with QOQO Tech. The Visitors are encouraged to read this
            Policy before using the Website. By using the Website, you agree to
            the terms and conditions of this Policy and the Terms and
            Conditions, available at www.qoqotech.in
          </Paragraph>

          <Title level={3}>Personal information we collect</Title>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            When you visit the Site, we automatically collect certain
            information about your device, including information about your web
            browser, IP address, time zone, and some of the cookies that are
            installed on your device. Additionally, as you browse the Site, we
            collect information about the individual web pages or products that
            you view, what websites or search terms referred you to the Site,
            and information about how you interact with the Site. We refer to
            this automatically-collected information as “Device Information”.
          </Paragraph>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            We collect Device Information using the following technologies:
            <br />
            - “Cookies” are data files that are placed on your device or
            computer and often include an anonymous unique identifier. For more
            information about cookies, and how to disable cookies, visit
            http://www.allaboutcookies.org.
            <br />
            - “Log files” track actions occurring on the Site, and collect data
            including your IP address, browser type, Internet service provider,
            referring/exit pages, and date/time stamps.
            <br />
            - “Web beacons”, “tags”, and “pixels” are electronic files used to
            record information about how you browse the Site.
            <br />
          </Paragraph>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            Additionally when you make a purchase or attempt to make a purchase
            through the Site, we collect certain information from you, including
            your name, billing address, shipping address, payment information
            (including credit card numbers, email address, and phone number. We
            refer to this information as “Order Information”.
          </Paragraph>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            When we talk about “Personal Information” in this Privacy Policy, we
            are talking both about Device Information and Order Information.
          </Paragraph>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            By subscribing to QOQO Tech text notifications, you agree to receive
            automated marketing text messages from us about our products and
            services at the phone number you provided when you subscribed, and
            that the messages may be sent via automatic telephone dialing system
            or other technology. Message frequency is recurring. Consent is not
            a condition of purchase. Message and data rates may apply. Reply
            STOP, END, CANCEL, UNSUBSCRIBE or QUIT to opt-out and HELP for
            customer support. You may receive an additional text message
            confirming your decision to opt-out. You understand and agree that
            attempting to opt-out by any means other than texting the opt-out
            commands above is not a reasonable means of opting out.
          </Paragraph>
          <Title level={3}>How do we use your personal information?</Title>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            We use the Order Information that we collect generally to fulfill
            any orders placed through the Site (including processing your
            payment information, arranging for shipping, and providing you with
            invoices and/or order confirmations). Additionally, we use this
            Order Information to:
          </Paragraph>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            - Communicate with you;
            <br />
            - Screen our orders for potential risk or fraud; and
            <br />
            - When in line with the preferences you have shared with us, provide
            you with information or advertising relating to our products or
            services.
            <br />
          </Paragraph>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            We use the Device Information that we collect to help us screen for
            potential risk and fraud (in particular, your IP address), and more
            generally to improve and optimize our Site (for example, by
            generating analytics about how our customers browse and interact
            with the Site, and to assess the success of our marketing and
            advertising campaigns).
          </Paragraph>
          <Title level={3}>Sharing you personal Information</Title>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            We share your Personal Information with third parties to help us use
            your Personal Information, as described above. For example we also
            use Google Analytics to help us understand how our customers use the
            Site -- you can read more about how Google uses your Personal
            Information here: https://www.google.com/intl/en/policies/privacy/.
            You can also opt-out of Google Analytics here:
            https://tools.google.com/dlpage/gaoptout.
          </Paragraph>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            Finally, we may also share your Personal Information to comply with
            applicable laws and regulations, to respond to a subpoena, search
            warrant or other lawful request for information we receive, or to
            otherwise protect our rights.
          </Paragraph>
          <Title level={3}>Behavioural advertising</Title>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            As described above, we use your Personal Information to provide you
            with targeted advertisements or marketing communications we believe
            may be of interest to you. For more information about how targeted
            advertising works, you can visit the Network Advertising
            Initiative’s (“NAI”) educational page at
            http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
          </Paragraph>
          <Paragraph align="left" style={{ fontSize: "16px" }}></Paragraph>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            You can opt out of targeted advertising by using the links below:
            <br />
            - Facebook: https://www.facebook.com/settings/?tab=ads
            <br />
            - Google: https://www.google.com/settings/ads/anonymous
            <br />
            - Bing:
            https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
            <br />
          </Paragraph>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            Additionally, you can opt out of some of these services by visiting
            the Digital Advertising Alliance’s opt-out portal at:
            http://optout.aboutads.info/.
          </Paragraph>
          <Title level={3}>Do not track</Title>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            Please note that we do not alter our Site’s data collection and use
            practices when we see a Do Not Track signal from your browser.
          </Paragraph>
          <Title level={3}>Your rights</Title>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            If you are a European resident, you have the right to access
            personal information we hold about you and to ask that your personal
            information be corrected, updated, or deleted. If you would like to
            exercise this right, please contact us through the contact
            information below.
          </Paragraph>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            Additionally, if you are a European resident we note that we are
            processing your information in order to fulfill contracts we might
            have with you (for example if you make an order through the Site),
            or otherwise to pursue our legitimate business interests listed
            above. Additionally, please note that your information will be
            transferred outside of Europe, including to Canada and the United
            States.
          </Paragraph>
          <Title level={3}>Data retention</Title>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            When you place an order through the Site, we will maintain your
            Order Information for our records unless and until you ask us to
            delete this information.
          </Paragraph>
          <Title level={3}>Changes</Title>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            We may update this privacy policy from time to time in order to
            reflect, for example, changes to our practices or for other
            operational, legal or regulatory reasons.
          </Paragraph>
          <Title level={3}>Minors</Title>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            The Site is not intended for individuals under the age of 18 years
          </Paragraph>
          <Title level={3}>Contact us</Title>
          <Paragraph align="left" style={{ fontSize: "16px" }}>
            For more information about our privacy practices, if you have
            questions, or if you would like to make a complaint, please contact
            us by e mail at info@qoqotech.in or by mail using the details
            provided below:
          </Paragraph>
        </Col>
      </Row>
    </>
  );
};
export default PrivacyPolicy;
