import { Card, Row, Col, Button, Space, Empty, Typography } from "antd";
import { useEffect, useState } from "react";
import { DeleteFilled, ShoppingCartOutlined } from "@ant-design/icons";
import image1 from "../../assets/01.svg";
import "./CartStyle/firstSection.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  addProductToCart,
  deleteProductFromCart,
  getUserCartList,
  updateProductFromCart,
} from "../../api/user";
import { updateCartItem } from "../../Redux/actionTypes";

const { Text } = Typography;

const ResponsiveCartDetail = () => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.cartItems);
  const [totalAmount, setTotalAmount] = useState(0);

  const incrementQuantity = (productDetails) => {
    const productDetailsAndCount = {
      product_id: productDetails?.product_id,
      count: 1,
    };
    addProductToCart(productDetailsAndCount).then((response) => {
      getUserCartList().then((response) => {
        console.log(response);
        dispatch(updateCartItem(response));
      });
    });
  };

  const decrementQuantity = async (productDetails) => {
    if (productDetails.count > 1) {
      const payload = {
        id: productDetails.id,
        count: productDetails.count - 1,
      };
      await updateProductFromCart(payload);
      getUserCartList().then((response) => {
        dispatch(updateCartItem(response));
      });
    } else {
      deleteItemFromCart(productDetails);
    }
  };

  const deleteItemFromCart = async (productDetails) => {
    const productidObj = {
      id: productDetails.id,
    };
    await deleteProductFromCart(productidObj);
    getUserCartList().then((response) => {
      dispatch(updateCartItem(response));
    });
  };

  const getTotalAmount = (cartItems) => {
    let totalAmount = 0;
    if (cartItems?.length) {
      cartItems.forEach((item) => {
        totalAmount += item.sellingPrice * item.count;
      });
    }
    setTotalAmount(totalAmount);
  };
  useEffect(() => {
    getTotalAmount(cartItems);
  }, [cartItems]);
  console.log(cartItems);
  return (
    <>
      <section
        className="first-resp"
        style={{ width: "90%", margin: "auto", marginTop: "50px" }}
      >
        {cartItems.length ? (
          <>
            {cartItems.map((item) => {
              return (
                <>
                  <Typography
                    style={{
                      border: "1px solid #E3E3E3",
                      borderRadius: "10px",
                      padding: "10px",
                      width: "100%",
                      backgroundColor: "#F0F0F0",
                    }}
                  >
                    <Row gutter={10}>
                      <Col span={8}>
                        <div
                          className="width"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <img
                            src={image1}
                            alt="Product"
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                            }}
                          />
                        </div>
                      </Col>
                      <Col span={16}>
                        <div style={{ paddingLeft: "8px" }}>
                          <p style={{ marginBottom: "0" }}>
                            <span
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                              }}
                            >
                              {item.name}
                            </span>
                          </p>
                          <p style={{ margin: "0" }}>Color: {item.color}</p>
                          <p
                            className="bold"
                            style={{
                              fontSize: "18px",
                            }}
                          >
                            Rs. {item.sellingPrice}
                          </p>
                        </div>
                        <Space style={{ paddingLeft: "10px" }}>
                          <Button
                            onClick={() => decrementQuantity(item)}
                            className="bold"
                          >
                            -
                          </Button>
                          <span className="bold">{item.count}</span>
                          <Button
                            onClick={() => incrementQuantity(item)}
                            className="bold"
                          >
                            +
                          </Button>

                          <Button
                            className="bold"
                            onClick={() => deleteItemFromCart(item)}
                          >
                            <DeleteFilled />
                          </Button>
                        </Space>
                      </Col>
                    </Row>
                  </Typography>
                </>
              );
            })}
          </>
        ) : (
          <>Empty cart</>
        )}
      </section>
      {/* checkout section start */}
      <section
        className="first pb-40"
        style={{
          width: "90%",
          margin: " 20px auto",
        }}
      >
        <Typography
          style={{
            border: "1px solid #ED302C",
            borderRadius: "10px",
            padding: "15px",
            width: "100%",
            backgroundColor: "#FFE5DB",
          }}
        >
          <div
            style={{
              paddingBottom: "5px",
              fontSize: "14px",
            }}
          >
            <Text>{`Selected Item (${cartItems.length})`}</Text>
          </div>

          <div
            style={{
              paddingBottom: "3px",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            <Text strong style={{ textAlign: "right" }}>
              Subtotal : {totalAmount}
            </Text>
          </div>
          <div
            style={{
              color: "gray",
            }}
          >
            Taxes and shipping calculated at checkout
          </div>
        </Typography>

        <div>
          <Link to={"/checkout/address"}>
            <Button className="bold checkout">Checkout</Button>
          </Link>
        </div>

        <></>
      </section>
    </>
  );
};

export default ResponsiveCartDetail;
