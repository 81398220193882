import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Row,
  Rate,
  Typography,
  Layout,
  message,
  Spin,
} from "antd";
import { Card } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useNavigate } from "react-router-dom";
import { getOrders } from "../../api/order";
import { formatDate } from "../../utils/data-time";
import { createReview } from "../../api/product";
import { useForm } from "antd/es/form/Form";
import { useSelector } from "react-redux";
import useWindowDimensions from "../../utils/useWindowDimensions";

const OrderPage = () => {
  const { isMobile } = useWindowDimensions();
  const [orders, setOrders] = useState([]);
  const [showReview, setShowReview] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [form] = useForm();
  const authState = useSelector((state) => (state ? state.auth : []));
  const handleShowReviewButtonClick = (id) => {
    setShowReview((prevState) => ({
      ...prevState,
      [id]: !prevState[id] || false,
    }));
  };
  const handleFormSubmit = async (body, productId) => {
    if (authState.authToken !== null) {
      setIsLoading(true);
      let values = {
        id: productId,
        username: authState?.userDetails?.full_name,
        rating: body.rating,
        review: body.review,
        dateReviewed: new Date().toLocaleDateString(),
      };
      try {
        const { data } = await createReview(values);
        if (data) {
          console.log("- succes", data);
          form.resetFields();
          setShowReview(false);
          setIsLoading(false);
        }
      } catch (error) {
        message.error("Failed to add a review!");
        setIsLoading(false);
        console.log("- error", error);
      }
    } else {
      message.error("User not logged in!");
    }
  };

  const gray = {
    color: "gray",
  };
  const borderBottomStyle = {
    borderBottom: "1px solid #ccc",
    paddingBottom: "28px",
    marginBottom: "28px",
  };
  const handleReviewClick = () => {
    setShowReview(!showReview);
  };
  const handleViewItemClick = (productId) => {
    navigate(`/product/${productId}`);
  };
  const navigate = useNavigate();
  const checkUserLoggedIn = () => {
    const otpless_user_token = JSON.parse(
      localStorage.getItem("otpless_user_token")
    );

    if (!otpless_user_token) {
      navigate("/eStore");
    }
  };
  const fetchOrders = async () => {
    setIsLoading(true);
    const orders = await getOrders();
    if (orders) {
      console.log("myAllOrders", orders);
      let sortedOrders = orders.sort((a, b) => {
        const dateA = new Date(a.createdAt).getTime();
        const dateB = new Date(b.createdAt).getTime();
        return dateB - dateA;
      });
      setOrders(sortedOrders);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    checkUserLoggedIn();
    fetchOrders();
  }, []);

  return (
    <Layout className="user-layout">
      {isLoading ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin />
        </div>
      ) : (
        <>
          {orders.length > 0 && (
            <Typography style={{}}>{`${orders.length} Order${
              orders.length > 1 ? "s" : ""
            } Placed`}</Typography>
          )}

          {orders.map((order, index1) => {
            return (
              <>
                {order["order_items"]?.map((orderItem, index2) => {
                  const formKey = `form${index1}${index2}`;
                  return (
                    <Card
                      key={`${index1}${index2}`}
                      style={{
                        margin: "20px",
                      }}
                    >
                      <Row>
                        <Col span={12}>
                          <Row>
                            <Col xs={24} sm={24} md={24} lg={8} span={8}>
                              <span style={gray}>ORDER PLACED</span>
                              <br />
                              {formatDate(order.createdAt)}
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={8} span={8}>
                              <span style={gray}>TOTAL</span>
                              <br />
                              <span>₹{orderItem.sellingPrice}</span>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={8} span={8}>
                              <span style={gray}>SHIP TO</span>
                              <br />
                              {order?.address?.firstName +
                                " " +
                                order?.address?.lastName}
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          span={12}
                          style={{
                            textAlign: "right",
                            color: "gray",
                            paddingRight: "20px",
                          }}
                        >
                          <span
                            style={{
                              color: "black",
                              fontSize: "16px",
                              fontWeight: "bold",
                            }}
                          >
                            {order.status} {order.deliveryDate}
                          </span>
                          <br />
                          {order.deliveryDetails} <br /> <br />
                          {order.status === "Delivered" &&
                            order.user_id === authState?.userDetails.id && (
                              <Button
                                onClick={() =>
                                  handleShowReviewButtonClick(
                                    orderItem.product_id + order.id
                                  )
                                }
                              >
                                Write Review
                              </Button>
                            )}
                        </Col>
                        <Col
                          xs={24}
                          sm={24}
                          md={24}
                          lg={12}
                          span={12}
                          style={{ textAlign: "right", color: "gray" }}
                        >
                          ORDER # <br /> {order.id}
                        </Col>
                      </Row>
                      <Typography style={borderBottomStyle}></Typography>
                      <Row>
                        <Col span={isMobile ? 24 : 12}>
                          <Row type="flex" justify="center" align="top">
                            <Col
                              xs={12}
                              sm={12}
                              md={8}
                              lg={8}
                              span={8}
                              style={{ textAlign: "center" }}
                            >
                              <img
                                src={orderItem?.productImages?.[0]}
                                alt={orderItem.name}
                                style={{ width: "90px", height: "81px" }}
                              />
                            </Col>
                            <Col span={12}>
                              <Typography
                                style={{ fontSize: isMobile ? "20px" : "25px" }}
                              >
                                {orderItem.name}
                              </Typography>
                              <br />
                              <Button
                                onClick={() =>
                                  handleViewItemClick(orderItem.product_id)
                                }
                              >
                                View Item
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                        {!isMobile && (
                          <Col
                            span={12}
                            style={{
                              textAlign: "right",
                              color: "gray",
                              paddingRight: "20px",
                            }}
                          >
                            <span
                              style={{
                                color: "black",
                                fontSize: "16px",
                                fontWeight: "bold",
                              }}
                            >
                              {order.status} {order.deliveryDate}
                            </span>
                            <br />
                            {order.deliveryDetails} <br /> <br />
                            {order.status === "Delivered" &&
                              order.user_id === authState?.userDetails.id && (
                                <Button
                                  onClick={() =>
                                    handleShowReviewButtonClick(
                                      orderItem.product_id + order.id
                                    )
                                  }
                                >
                                  Write Review
                                </Button>
                              )}
                          </Col>
                        )}
                      </Row>
                      {showReview[orderItem.product_id + order.id] && (
                        <>
                          <Typography style={borderBottomStyle}></Typography>
                          <Form
                            form={form}
                            key={formKey}
                            onFinish={(values) =>
                              handleFormSubmit(values, orderItem.product_id)
                            }
                            layout="vertical"
                          >
                            <Row gutter={32}>
                              <Col span={16}>
                                <Form.Item name="review">
                                  <TextArea
                                    placeholder="Write your review here"
                                    rows={3}
                                    style={{
                                      width: "100%",
                                      backgroundColor: "#F4F7FC",
                                      padding: "20px",
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  name="rating"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please give a rating!",
                                    },
                                  ]}
                                  label="Overall Rating"
                                >
                                  <Rate style={{ fontSize: 24 }} />
                                </Form.Item>
                              </Col>
                            </Row>

                            <Form.Item style={{ paddingTop: "20px" }}>
                              <Button
                                type="primary"
                                onClick={() =>
                                  handleShowReviewButtonClick(
                                    orderItem.product_id + order.id
                                  )
                                }
                                style={{
                                  backgroundColor: "white",
                                  color: "red",
                                  border: "1px solid red",
                                  height: "50px",
                                  width: "149px",
                                }}
                              >
                                Discard
                              </Button>
                              <Button
                                type="default"
                                htmlType="submit"
                                style={{
                                  marginLeft: 16,
                                  backgroundColor: "red",
                                  color: "white",
                                  height: "50px",
                                  width: "149px",
                                }}
                              >
                                Add Review
                              </Button>
                            </Form.Item>
                          </Form>
                        </>
                      )}
                    </Card>
                  );
                })}
              </>
            );
          })}
        </>
      )}
    </Layout>
  );
};

export default OrderPage;
