import React, { useEffect, useState } from "react";
import { Row, message } from "antd";
import { useSelector } from "react-redux";
import { useForm } from "antd/lib/form/Form";
import OrderSummary from "../Components/CheckOut/OrderSummary";
import UserAddressCard from "../Components/CheckOut/UserAddressCard";
import { getUserAddress, removeAddress } from "../api/user";
import { useNavigate } from "react-router-dom";
import { Address } from "../Components/Address/Address";

const UserAddressSection = () => {
  const userDetails = useSelector((state) => state.auth.userDetails);
  const [addressList, setAddressList] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isEditAddress, setIsEditAddress] = useState(false);

  const [form] = useForm();
  form.setFieldsValue({
    email: userDetails?.email,
  });
  form.setFieldsValue({
    mobile: userDetails?.mobile,
  });
  console.log("firstsectionchecckut", userDetails);
  const getAndStoreUserAddress = async () => {
    const addressList = await getUserAddress();
    setAddressList(addressList);
    console.log(addressList);
  };
  const handleAddressRemove = async (address) => {
    removeAddress(address).then((res) => {
      message.success("Address removed successfully");
      getAndStoreUserAddress();
    });
  };
  const navigate = useNavigate();

  const checkUserLoggedIn = () => {
    const otpless_user_token = JSON.parse(
      localStorage.getItem("otpless_user_token")
    );
    if (!otpless_user_token) {
      navigate("/eStore");
    }
  };
  useEffect(() => {
    checkUserLoggedIn();
    getAndStoreUserAddress();
  }, []);

  return (
    <>
      <Row gutter={16} style={{ margin: 0 }} type="flex" justify="center">
        {isEditAddress ? (
          <Address
            setIsEditAddress={setIsEditAddress}
            isEditAddress={isEditAddress}
            addressSelected={selectedAddress}
            setAddressList={setAddressList}
          />
        ) : (
          <UserAddressCard
            setIsEditAddress={setIsEditAddress}
            addressList={addressList}
            onEdit={(value) => {
              setSelectedAddress(value);
            }}
            onDelete={(value) => {
              handleAddressRemove(value);
            }}
          />
        )}
        <OrderSummary />
      </Row>
    </>
  );
};

export default UserAddressSection;
