import React from 'react'
import { Col, Typography, Image, Row } from "antd";
import HeadingText from '../HeadingText';
import watchOne from "../../../assets/Watchone1.svg"
import prodOne from "../../../assets/prodOne.png"
import "../ProductsSections/styles/productSecOne.css"

const ProductSecOne = () => {
  const { Text } = Typography;

  return (
    <Row className='prod-sec'>
      <Col xs={16} lg={16} className='prod-sec-one-col'>
        <div>
          <Text className='get-ready-to'>Get Ready to</Text>
        </div>
        <div>
          <Text className='rock-your-wrist'>Rock Your Wrist</Text>
        </div>
        <div>
          <Text className='decp-text'>Slim, Light, and Stylish AMOLED Watches!</Text>
        </div>
      </Col>

      <Col xs={0} lg={8}>
        <Image src={prodOne} alt='prod-one' preview={false} />
      </Col>
      <Col xs={8} lg={0} className='prod-sec-image-container'>
        <Image className='pord-sec-image' src={watchOne} preview={false} />
      </Col>
    </Row>
  )
}

export default ProductSecOne