import axios from "axios";

const productApiService = axios.create({
  // baseURL: 'http://localhost:5000/v1/api', // Your API base URL
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:5002/v1/api/product"
      : "https://api.qoqotech.in/v1/api/product",
});

// Request interceptor to include auth token in specific requests
productApiService.interceptors.request.use(
  (config) => {
    let authToken = localStorage.getItem("auth_token");

    if (authToken) {
      // Check if the request URL requires authorization (e.g., cart-related endpoints)
      if (config.url.includes("cart") || config.url.includes("review")) {
        config.headers["Authorization"] = `Bearer ${authToken}`;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default productApiService;
