import {
  SettingOutlined,
  UserOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Menu, Modal, Button, Typography } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setActiveSection } from "../Redux/actionTypes";
import React from "react";

function Sidebar() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState("/");
  const authState = useSelector((state) => (state ? state.auth : []));
  const menuItems = [
    {
      label: "Profile",
      key: "/user/profile",
      icon: <SettingOutlined />,
    },
    {
      label: "Orders",
      key: "/user/orders",
      icon: <SettingOutlined />,
    },
    {
      label: "Address",
      key: "/user/address",
      icon: <SettingOutlined />,
    },
    {
      label: "Logout",
      key: "logout",
      icon: <UserOutlined />,
    },
  ];

  const filteredMenuItems = () => {
    if (authState.userDetails === null) {
      return menuItems.filter((item) => item.key !== "logout");
    }
    return menuItems;
  };
  console.log(authState);
  const logoutUser = () => {
    window.localStorage.removeItem("auth_token");
    window.localStorage.removeItem("otpless_user_token");
    window.localStorage.removeItem("user_details");
    navigate("/");
    navigate(0);
    setIsModalOpen(false);
  };

  useEffect(() => {
    const pathName = location.pathname;
    setSelectedKeys(pathName);
    dispatch(setActiveSection(pathName.slice(1)));
  }, [location.pathname]);

  const navigate = useNavigate();
  return (
    <div className="SideMenu">
      <Modal
        centered
        title={[
          <p>
            <WarningOutlined style={{ color: "#d12815" }} /> Are you sure you
            want to logout?
          </p>,
        ]}
        open={isModalOpen}
        footer={[
          <Button
            style={{ backgroundColor: "#d12815" }}
            type="primary"
            onClick={logoutUser}
          >
            Yes
          </Button>,
          <Button onClick={() => setIsModalOpen(false)}>No</Button>,
        ]}
      >
        <Typography>Are you sure you want to logout?</Typography>
      </Modal>
      <Menu
        className="SideMenuVertical"
        onClick={(item) => {
          if (item.key === "logout") {
            setIsModalOpen(true);
          } else {
            navigate(item.key);
          }
        }}
        style={{ marginTop: "20px" }}
        selectedKeys={[selectedKeys]}
        defaultOpenKeys={["/catalog"]}
        mode="inline"
        items={filteredMenuItems()}
      ></Menu>
    </div>
  );
}
export default Sidebar;
