import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, Button } from 'antd';
import { DashboardOutlined } from '@ant-design/icons';
import success from "../../assets/Path 2.png";
import failed from "../../assets/Subtraction 1.png";
import { Link, useNavigate } from "react-router-dom";




const PaymentResponsePage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [paymentMode, setPaymentMode] = useState("");
  const [failureMessage, setFailureMessage] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [merchant_param1, setMerchantParam1] = useState("");
  const [merchant_param2, setMerchantParam2] = useState("");
  const [merchant_param3, setMerchantParam3] = useState("");
  const [paymentStatus, SetPaymentStatus] = useState(false);

  

  const checkUserLoggedIn = () => {
    const otpless_user_token = JSON.parse(
      localStorage.getItem("otpless_user_token")
    );

    if (!otpless_user_token) {
      navigate("/eStore");
    }
  };

  useEffect(() => {
    checkUserLoggedIn();
    const queryParams = new URLSearchParams(location.search);
    setPaymentMode(queryParams.get('payment_mode') || "");
    setFailureMessage(queryParams.get('failure_message') || "");
    setOrderStatus(queryParams.get('order_status') || "");
    setStatusMessage(queryParams.get('status_message') || "");
    setMerchantParam1(queryParams.get('merchant_param1') || "");
    setMerchantParam2(queryParams.get('merchant_param2') || "");
    setMerchantParam3(queryParams.get('merchant_param3') || "");

    const status = queryParams.get('status');
    if (status && status.toLowerCase() === "success") {
        SetPaymentStatus(true);
    }
    else if( status && status.toLowerCase() === "failure") {
      SetPaymentStatus(false);
    }
    else{
      navigate("/");
    }
  }, [location.search]);

  return (
    paymentStatus ? (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Card style={{ width: 400 }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={success} alt="Header Icon" style={{ width: '20%', height: 75, objectFit: 'cover', borderRadius: '50%' }} />
          </div>
          <div style={{ padding: '16px 24px', textAlign: 'center'  }}>
            <h2>Your transaction is successful. Thank You!</h2>
            {/* <p>Payment Mode: {paymentMode}</p>
            <p>Order Status: {orderStatus}</p>
            <p>Status Message: {statusMessage}</p>
            <p>User ID: {merchant_param1}</p>
            <p>Address ID: {merchant_param2}</p>
            <p>User Name: {merchant_param3}</p> */}
          </div>
          <div style={{ textAlign: 'center', padding: '8px 0' }}>
              <Link to="/">
                  <Button type="primary" icon={<DashboardOutlined />}>
                      Go to Dashboard
                  </Button>
              </Link>
          </div>
        </Card>
      </div>
    ): 
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Card style={{ width: 400 }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={failed} alt="Header Icon" style={{ width: '20%', height: 75, objectFit: 'cover', borderRadius: '50%' }} />
          </div>
          <div style={{ padding: '16px 24px', textAlign: 'center'  }}>
            <h2>Your transaction is failed. Thank You!</h2>
            {/* <p>Payment Mode: {paymentMode}</p>
            <p>Order Status: {orderStatus}</p>
            <p>Status Message: {statusMessage}</p>
            <p>User ID: {merchant_param1}</p>
            <p>Address ID: {merchant_param2}</p>
            <p>User Name: {merchant_param3}</p> */}
          </div>
          <div style={{ textAlign: 'center', padding: '8px 0' }}>
              <Link to="/">
                  <Button type="primary" icon={<DashboardOutlined />}>
                      Go to Dashboard
                  </Button>
              </Link>
          </div>
        </Card>
      </div>
  );
}

export default PaymentResponsePage;



