export const initOTPless = (callback) => {
  console.log("called init");
  const otplessInit = Reflect.get(window, "otplessInit");

  const loadScript = () => {
    const script = document.createElement("script");
    script.src = "https://otpless.com/v2/auth.js";
    script.id = "otpless-sdk";
    script.setAttribute("data-appid", "NUGP28DHOH51CEHO37Y1");
    document.body.appendChild(script);
  };

  otplessInit ? otplessInit() : loadScript();

  Reflect.set(window, "otpless", callback);
};
