import React from "react";
import "./productdetailStyle/secondSections.css";
import { Col, Row, Tabs } from "antd";
import ProductDetailReview from "./ProductReview";

const SecondSection = ({ productData }) => {
  const {
    countryOfOrigin,
    name,
    category,
    color,
    productDescription,
    productFeature,
    packedBy,
    includedComponents,
  } = productData;
  let featureList = productFeature.split(",");
  const items = [
    {
      key: "1",
      label: "Description",
      children: (
        <div style={{ paddingTop: "20px", fontWeight: 400 }}>
          <div>{productDescription}</div>
          <div style={{ paddingTop: "20px", fontWeight: 400 }}>
            <h3>Product Features</h3>
            <ul>
              {featureList?.map((item) => (
                <li>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: "Other Details",
      children: (
        <>
          <div style={{ paddingTop: "20px", fontWeight: 600 }}>
            <div>Specifications:</div>
            <div style={{ fontWeight: 300 }}>
              <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                <li
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <span style={{ flex: 1 }}>Model Name</span>
                  <span style={{ flex: 1 }}>{name}</span>
                </li>
                <li
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <span style={{ flex: 1 }}>Category</span>
                  <span style={{ flex: 1 }}>{category}</span>
                </li>
                <li
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <span style={{ flex: 1 }}>Colour</span>
                  <span style={{ flex: 1 }}>{color}</span>
                </li>
                <li
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <span style={{ flex: 1 }}>Country of Origin</span>
                  <span style={{ flex: 1 }}>{countryOfOrigin}</span>
                </li>
              </ul>
            </div>
          </div>
          <div style={{ paddingTop: "20px", fontWeight: 600 }}>
            <div>What is in the box?</div>
            <div style={{ fontWeight: 300 }}>
              <ul>
                <li className="li-alignment">{includedComponents}</li>
              </ul>
            </div>
          </div>
          {packedBy !== undefined && (
            <div style={{ paddingTop: "20px", fontWeight: 600 }}>
              <div>Packed By</div>
              <div style={{ fontWeight: 300 }}>
                <ul>
                  <li className="li-alignment">{packedBy}</li>
                </ul>
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      key: "3",
      label: "Review",
      children: (
        <>
          <ProductDetailReview productData={productData} />
        </>
      ),
    },
  ];
  const onChange = (key) => {
    console.log(key);
  };

  return (
    <>
      <Row style={{ margin: 0 }} gutter={20} type="flex" justify="center" align="middle">
        <Col xs={22} sm={22} md={22} lg={22}>
          <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </Col>
      </Row>
    </>
  );
};

export default SecondSection;
