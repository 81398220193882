import { Card, Row, Col, Button, Space, Empty, Typography, Flex } from "antd";
import { useEffect, useState } from "react";
import { DeleteFilled, ShoppingCartOutlined } from "@ant-design/icons";
import image1 from "../../assets/01.svg";
import "./CartStyle/firstSection.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  addProductToCart,
  deleteProductFromCart,
  getUserAddress,
  getUserCartList,
  updateProductFromCart,
} from "../../api/user";
import { updateCartItem } from "../../Redux/actionTypes";
import { useNavigate } from "react-router-dom";
import React from "react";
const { Text } = Typography;

const CartDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [totalAmount, setTotalAmount] = useState(0);
  const cartItems = useSelector((state) => state.cart.cartItems);
  const [addressList, setAddressList] = useState([]);

  const incrementQuantity = (productDetails) => {
    const productDetailsAndCount = {
      product_id: productDetails.product_id,
      count: 1,
    };
    addProductToCart(productDetailsAndCount).then((response) => {
      getUserCartList().then((response) => {
        console.log(response);
        dispatch(updateCartItem(response));
      });
    });
  };
  const onCheckoutButtonClicked = () => {
    // if (addressList.length) {
    //   navigate("/checkout/payment");
    // } else {
    navigate("/checkout/address");
    // }
  };
  const deleteItemFromCart = async (productDetails) => {
    const productidObj = {
      id: productDetails.id,
    };
    await deleteProductFromCart(productidObj);
    getUserCartList().then((response) => {
      dispatch(updateCartItem(response));
    });
  };
  const decrementQuantity = async (productDetails) => {
    if (productDetails.count > 1) {
      const payload = {
        id: productDetails.id,
        count: productDetails.count - 1,
      };
      await updateProductFromCart(payload);
      getUserCartList().then((response) => {
        dispatch(updateCartItem(response));
      });
    } else {
      deleteItemFromCart(productDetails);
    }
  };
  const getTotalAmount = (cartItems) => {
    let totalAmount = 0;
    if (cartItems?.length) {
      cartItems.forEach((item) => {
        totalAmount += item.sellingPrice * item.count;
      });
    }
    setTotalAmount(totalAmount);
  };
  const getAndStoreUserAddress = async () => {
    const addressList = await getUserAddress();
    setAddressList(addressList);
    console.log(addressList);
  };
  useEffect(() => {
    getAndStoreUserAddress();
  }, []);
  useEffect(() => {
    getTotalAmount(cartItems);
  }, [cartItems]);

  return (
    <>
      <section className="first">
        <Row type="flex" justify="center" align="middle" style={{ margin: '20px' }}>
          <Col span={12} style={{ alignItems: "center" }}>
            <Text className="title">Your cart</Text>
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              justifyContent: "right"
            }}
          >
            <Link to={"/eStore"}>
              <Text className="continue">Continue shopping</Text>
            </Link>
          </Col>
        </Row>
        {cartItems?.length ? (
          <Row
            type="flex"
            justify="start"
            align="middle"
            style={{ margin: "20px 25px 0 25px" }}
            span={10}
          >
            <Col span={14} style={{ alignItems: "center" }}>
              <p className="bold">Product</p>
            </Col>
            <Col
              span={6}
              style={{
                display: "flex",
                justifyContent: "left",
              }}
            >
              <Text className="bold">Quantity</Text>
            </Col>
            <Col
              span={4}
              style={{
                display: "flex",
                justifyContent: "left",
              }}
            >
              <p className="bold">Total</p>
            </Col>
          </Row>
        ) : (
          <></>
        )}
        {cartItems?.length ? (
          cartItems.map((item, index) => {
            return (
              <Card key={index} className="card-alignment">
                <Row
                  type="flex"
                  justify="start"
                  align="top"
                >
                  <Col span={14}>
                    <Row type="flex" justify="between" align="top" style={{ margin: '0 0 0 35px' }}>
                      <Col xs={24} sm={8}>
                        <div
                          className="product-image-wrapper"
                        >
                          <img
                            className="product-image"
                            src={item.productImages?.[0]}
                            alt="Product"
                          />
                        </div>
                      </Col>
                      <Col xs={24} sm={16}>
                        <div style={{ paddingLeft: "30px" }}>
                          <Text className="product-title">
                            {item.name}
                          </Text>
                          <div style={{ marginTop: '5px' }}>
                            <Text
                              className="product-price"
                            >
                              Rs. {item.sellingPrice}
                            </Text>
                          </div>
                          <div className="product-details-container">
                            <p
                              style={{
                                color: "gray",
                                "@media (max-width: 768px)": { fontSize: "12px" },
                              }}
                            >
                              Color: {item.color}
                              <br />
                              Style: Modern
                              <br />
                              Brand: 0000
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    span={6}
                    style={{
                      display: "flex",
                      justifyContent: "left",
                    }}
                  >
                    <Space>
                      <div className="quantity-container">
                        <Flex>
                          <div className="quantity-container-wrapper">
                            <span>
                              <Text onClick={() => decrementQuantity(item)} className="increment-decrement-btn">-</Text>
                            </span>
                            <span className="item-count">{item.count}</span>
                            <span>
                              <Text onClick={() => incrementQuantity(item)} className="increment-decrement-btn">+</Text>
                            </span>
                          </div>
                        </Flex>
                      </div>
                      <Button
                        className="delete-btn"
                        style={{ width: "24px", border: "none" }}
                        onClick={() => deleteItemFromCart(item)}
                      >
                        <DeleteFilled />
                      </Button>
                    </Space>
                  </Col>
                  <Col
                    span={4}
                    style={{
                      display: "flex",
                      justifyContent: "left",
                    }}
                  >
                    <Space>
                      <span className="bold">Rs {item.sellingPrice * item.count}</span>
                    </Space>
                  </Col>
                </Row>
              </Card>
            );
          })
        ) : (
          <Empty
            image={<ShoppingCartOutlined style={{ fontSize: 100 }} />}
            imageStyle={{
              height: 100,
            }}
            description={
              <Space direction="vertical" style={{ margin: '30px 0' }}>
                <span style={{ fontWeight: '600', fontSize: '22px' }}>Your cart is empty</span>
                {/* Add additional message or content here */}
              </Space>
            }
          />
        )}
        {cartItems?.length ? (
          <Row type="flex" justify="center" className="pt">
            <Col span={13}>
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "80%",
                }}
                className="bottom-border "
              >
                <span className="bold">Discount Code</span>
                <span
                  className="bold"
                  style={{ color: #04b2a9, textDecoration: "underline" }}
                >
                  Apply
                </span>
              </div> */}
            </Col>
            <Col span={10}>
              <div className="checkout-container">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Text className="bold">Subtotal: Rs.{totalAmount}</Text>
                </div>
                <Text
                  style={{
                    color: "#848397",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  Taxes and shipping calculated at checkout
                </Text>
                <div style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}>
                  <Button
                    className="checkout"
                    style={{ marginTop: "20px" }}
                    onClick={() => onCheckoutButtonClicked()}
                  >
                    Check Out
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </section>
    </>
  );
};

export default CartDetail;
