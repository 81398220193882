import React from "react";
import "../AboutSections/styles/ThirdSection.css";
import { Col, Row, Typography, Image } from "antd";
import sWatch from "../../../assets/mission3.png";

const AboutSecThirdMobile = () => {
  const { Text } = Typography;
  return (
    <>
      <Row>
        <Col span={24}>
          <div className="about-third-top">
            <Image
              className="aboutbanner-img"
              src={sWatch}
              width="100%"
              alt="watch-img"
              height="509px"
              preview={false}
            />
            <div className="right">
            <div className="img-content-mobile">
              <Text className="title">Mission</Text>
              <Text className="sub-title">
                Improving lives with intuitive technology and seamless designs
              </Text>
            </div>
          </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default AboutSecThirdMobile;
