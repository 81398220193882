import React from "react";
import "../AboutSections/styles/FirstSection.css";
import { Col, Typography, Image } from "antd";
import aboutBanner from "../../../assets/about-banner-mobile.png";
import aboutus from "../../../assets/aboutus.svg";

const AboutSecOneMobile = () => {
  const { Text } = Typography;
  return (
    <Col span={24}>
      <div>
        <Image
          className="aboutbanner-img"
          src={aboutBanner}
          width="100%"
          alt="watch-img"
          height="509px"
          preview={false}
        />
        <div className="inner-content-mobile">
        <Image
          src={aboutus}
          width={"240px"}
          height={"100px"}
          alt="watch-img"
          preview={false}
        />
          <Text className="sub-content-mobile">
            <p>
              Bridging the gap between the communities of display electronics,
              sound engineering and vision sciences
            </p>
          </Text>
        </div>
      </div>
    </Col>
  );
};

export default AboutSecOneMobile;
