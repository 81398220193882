import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Typography,
  Checkbox,
  Select,
} from "antd";
import { useSelector } from "react-redux";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/es/input/TextArea";
import { useNavigate, useParams } from "react-router-dom";
import { addAddress, getUserAddress, updateAddress } from "../../api/user";
const { Item } = Form;
const { Option } = Select;
const states = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
];

const closeButton = {
  backgroundColor: "#fff",
  color: "#04B2A9",
  height: "44px",
  width: 100,
  marginLeft: 10,
};

const submitButton = {
  backgroundColor: "#04B2A9",
  color: "#fff",
  height: "44px",
  width: 130,
};

export const Address = ({
  setIsNewAddressAdd,
  addressSelected,
  isEditAddress,
  setAddressList,
  setIsEditAddress,
}) => {
  const params = useParams();
  console.log(params);
  const userDetails = useSelector((state) => state.auth.userDetails);
  const [shippingAddress, setShippingAddress] = useState([]);

  const navigate = useNavigate();
  const [form] = useForm();
  // form.setFieldsValue({
  //   email: userDetails?.email,
  // });
  // form.setFieldsValue({
  //   mobile: userDetails?.mobile,
  // });
  const [formData, setFormData] = useState({
    name: "",
    emailMe: false,
    country: "",
    firstName: "",
    lastName: "",
    address: "",
    mobile: "",
    city: "",
    state: "",
    pincode: "",
    saveInfo: false,
  });

  const getAndStoreUserAddress = async () => {
    const addressList = await getUserAddress();
    if (setAddressList) {
      setAddressList(addressList);
      console.log(addressList);
    }
  };
  const handleSubmit = (values) => {
    let body = {
      ...values,
      id: addressSelected?.id,
    };
    if (isEditAddress) {
      updateAddress(body).then((response) => {
        window.localStorage.setItem(
          "selectedAddress",
          JSON.stringify(response)
        );
        getAndStoreUserAddress();
        setIsEditAddress(false);
      });
    } else {
      addAddress(values).then((response) => {
        window.localStorage.setItem(
          "selectedAddress",
          JSON.stringify(response)
        );
        setIsNewAddressAdd(false);
      });
    }
  };

  useEffect(() => {
    if (addressSelected) {
      setFormData(addressSelected);
      form.setFieldsValue({
        ...addressSelected,
      });
    }
  }, [addressSelected, form]);

  return (
    <>
      <Form
        form={form}
        name="addAddressForm"
        initialValues={{ remember: true }}
        style={{ marginTop: "5px" }}
        onFinish={handleSubmit}
      >
        <Typography
          style={{ fontSize: "25px", fontWeight: "500", marginBottom: 10 }}
        >
          Add address
        </Typography>
        <Row gutter={16} style={{ marginTop: "5px" }}>
          <Col span={24}>
            <Item
              name="country"
              rules={[
                {
                  required: true,
                  message: "please enter country name",
                },
              ]}
            >
              <Input size="large" placeholder="Country name" />
            </Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Item
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "Please input your first name!",
                },
              ]}
            >
              <Input size="large" placeholder="First Name" />
            </Item>
          </Col>
          <Col span={12}>
            <Item
              name="lastName"
              rules={[
                {
                  required: true,
                  message: "Please input your last name!",
                },
              ]}
            >
              <Input size="large" placeholder="Last Name" />
            </Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Item
              name="contact"
              rules={[
                {
                  required: true,
                  message: "Please enter you email or mobile!",
                },
              ]}
            >
              <Input size="large" placeholder="Enter you email or mobile" />
            </Item>
          </Col>
          <Col span={12}>
            <Item
              name="mobile"
              rules={[
                {
                  required: true,
                  message: "Please enter you mobile!",
                },
              ]}
            >
              <Input size="large" placeholder="Enter you mobile" />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Item
              name="address"
              rules={[
                {
                  required: true,
                  message: "Please input your Address!",
                },
              ]}
            >
              <TextArea size="large" placeholder="Address" autoSize />
            </Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Item
              name="city"
              rules={[
                {
                  required: true,
                  message: "city",
                },
              ]}
            >
              <Input size="large" placeholder="City" />
            </Item>
          </Col>
          <Col span={8}>
            <Item
              name="state"
              rules={[
                {
                  required: true,
                  message: "please select state",
                },
              ]}
            >
              <Select
                size="large"
                placeholder="Select State"
                style={{
                  width: "100%",
                  fontWeight: "bold",
                }}
              >
                {states.map((state, index) => (
                  <Option key={index} value={state}>
                    {state}
                  </Option>
                ))}
              </Select>
            </Item>
          </Col>
          <Col span={8}>
            <Item
              name="pincode"
              rules={[
                {
                  required: true,
                  message: "Pincode",
                },
              ]}
            >
              <Input size="large" type="number" placeholder="PIN code" />
            </Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Item
              name="save_as"
              rules={[
                {
                  required: true,
                  message: "please select address type",
                },
              ]}
            >
              <Select
                size="large"
                placeholder="Select address type"
                style={{
                  width: "100%",
                  fontWeight: "bold",
                }}
              >
                <Option value="Home">Home</Option>
                <Option value="Work">Work</Option>
              </Select>
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Item>
              <Button htmlType="submit" style={submitButton}>
                {isEditAddress ? "Update Address" : "Save Address"}
              </Button>
              <Button
                onClick={() => {
                  setIsNewAddressAdd(false);
                  setIsEditAddress(false);
                }}
                style={closeButton}
              >
                Close
              </Button>
            </Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};
