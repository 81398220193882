import React from "react";
import { Carousel } from "antd";
import { Helmet } from "react-helmet";
import FirstSection from "../components/HomeSections/FirstSection";
import SecondSection from "../components/HomeSections/SecondSection";
import ThirdSection from "../components/HomeSections/ThirdSection";
import FourthSection from "../components/HomeSections/FourthSection";
import Footer from "../components/Footer/Footer";
import BannerOne from "../components/HomeSections/BannerOne";
import BannerTwo from "../components/HomeSections/BannerTwo";
import "../css/home.css";

const contentStyle = {
  margin: 0,
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Home | QOQO TECH</title>
        <meta name="description" content="This is a description of my page" />
        <meta name="keywords" content="react, meta tags, seo" />
        <meta name="author" content="QoQo Tech" />
        <meta property="og:title" content="Home | QOQO TECH" />
        <meta
          property="og:description"
          content="This is a description of my page"
        />
      </Helmet>
      <section>
        <Carousel className="home-carousel" autoplay>
          <div>
            <FirstSection />
          </div>
          <div>
            <BannerOne />
          </div>
          <div>
            <BannerTwo />
          </div>
        </Carousel>
      </section>
      <SecondSection />
      <ThirdSection />
      <FourthSection />
      <Footer />
    </>
  );
};

export default Home;
