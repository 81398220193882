import insta from "../assets/insta.svg";
import linkdln from "../assets/linkdln.svg";
import twitter from "../assets/twitter.svg";
import facebook from "../assets/facebook.svg";

export const footerIcon = [
  { image: insta, url: "https://www.instagram.com/qoqo_tech/" },
  { image: linkdln, url: "https://in.linkedin.com/company/qoqo-tech" },
  //   twitter,
  { image: facebook, url: "https://www.facebook.com/qoqotech/" },
];
