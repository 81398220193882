import React from "react";
import { Row, Col } from "antd";
import MainMenu from "../presentation/components/Menu/MainMenu.jsx";

const Layout = ({ Component }) => {
  const closeModal = (e) => {
    const modalContainer = document.getElementById("modalContainer");
    modalContainer.style.display = "none";
  };
  return (
    <>
      {/* <div className="model-init">
        <div
          className="modal-container"
          id="modalContainer"
          style={{ display: "none" }}
          onClick={closeModal}
        >
          <div className="modal">
            <span className="close-icon" onClick={closeModal}>
              &times;
            </span>
            <div id="otpless-login-page"></div>
          </div>
        </div>
      </div> */}
      <Row>
        <Col span={24} style={{ position: "sticky", top: 0, zIndex: 100 }}>
          <MainMenu />
        </Col>
        <Col span={24}>
          <Component />
        </Col>
      </Row>
    </>
  );
};

export default Layout;
